import React, {useEffect} from 'react'
import CarouselFade from '../components/Iwrm';



let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Integrated Water Resources Management (IWRM) | AVE AGRO FARM";

},[]);

  return (
    <>
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Integrated Water Resources Management (IWRM)</h2>
                <p className='products-services-intro'>Activities done with different farmers to promote vegetation, soils and water sources protection at community level.</p>     
          </div>   
    </section>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    </>
  )
}

export default Home