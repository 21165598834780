import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';
const Clients = () => {
useEffect(()=>{
document.title = 'Gallery | AVE AGRO FARM';

},[]);



  return (
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Gallery</h2>
                {/* <p className='products-services-intro'>We deliver quality products to our clients. We have a team of dedicated staff who are friendly and knowledgeable about our work. We deal in the following:</p> */}
                {/* <h4 className='staff-title staff-title-a staff-title-b'>Products</h4> */}
                <div className='team-members team-members-a'>
                   <article className='team-member team-member-a'>
                  
                    <h4 className='staff-title staff-title-c'>Agronomy</h4>
                     <Link to='/agronomy' className='gallery-link'>See Photos</Link>
                   </article>
                   <article className='team-member team-member-a'>
                   
                    <h4 className='staff-title staff-title-c'>Integrated Water Resources Management (IWRM)</h4>
                    <Link to='/iwrm' className='gallery-link'>See Photos</Link>
                   
                   </article>
                    <article className='team-member team-member-a'>
                   
                    <h4 className='staff-title staff-title-c'>Irrigated Farming</h4>
                     <Link to='/irrigated-farming' className='gallery-link'>See Photos</Link>
                   
                   </article>
                   

                </div>
                {/* <h4 className='staff-title staff-title-a'>Services</h4>
           <div className='team-members'>
                   <article className='team-member'>
                    <h3 className='staff-name'>Ben Lakony</h3>
                    <img src='./images/18.jpg' alt='photo' className='staff-member'></img>
                    <h4 className='staff-title'>Chairperson Board of Directors</h4>
                    <p className='staff-details'>Ben is a very experienced person who is helping to organise and ensure smooth runing of the company. He has a Bachelor's degree in development studies and a diploma in counselling and guidance.</p>
                   </article>

                   
                </div> */}
          </div>   
    </section>
  )
}

export default Clients