import React, {useEffect} from 'react'
import CarouselFade from '../components/IrrigatedFarming';



let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Irrigated Farming | AVE AGRO FARM";

},[]);

  return (
    <>
    <section className='section-cover' >
           <div className='content-container'>
                <h2 className="home-h2">Irrigated Farming</h2>
                <p className='products-services-intro'>Farmers practicing the use of various irrigation techniques for enhancing their farming activities in the off-season.</p>     
          </div>   
    </section>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    </>
  )
}

export default Home