import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/1.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/5.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/26.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/27.jpg"
          alt="First slide"
        />
      </Carousel.Item>
          <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/28.jpg"
          alt="First slide"
        />
      </Carousel.Item>
               <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/29.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;