import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import MissionCarousel from '../components/Mission Carousel';
import {FaArrowAltCircleRight,FaQuoteLeft,FaUsers,FaHandshake} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Habakas Events";

},[]);

  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          {/* <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div> */}

                 <p className='home-p'>
               <strong>Habakas Events</strong> is an events management company located at Old Butabika Road, Mutungo Bbina - Uganda. We are committed to finding out what exactly our customers want and delivering solutions that exceed their expectations.
</p>
<p className='home-p'>
Our company offers high-end quality services in events management, tent rentals, chair & table rentals and any other items depending on the client’s request.
  </p>
  <p className='home-p'>
  Over the years, we have established strategic partnership with Uganda’s finest venues and suppliers, coupled with our highly creative, imaginative and innovative team, we are able to offer unbeatable services peculiar to the events industry. No matter how best you wish your event to be, no matter how great the scale, you can feel confident with us.  We are able to provide you with all solutions that combine and create a truly exceptional occasion.
  </p>


 
        </div>

    </section>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./images/1.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Experiences in the field</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>
 We have gained close to 6 years of working experience in training farmers on Good Agricultural practices, IWRM, Climate Smart Agriculture, suppling of quality planting materials to entities such as LLGs, other CBOs and individual commercial farmers for both birds, livestock and crops since we started working on in 2018.We have supplied DOCs to poultry farmers in Acholi, Lango, West Nile and South Sudan. We have mentored close to 30 youth in Acholi and Lango sub regions to be strong trainers of other farmers since we became operational even before getting incorporated. We work hand in hand with the lower local government officials in conducting farmers training from time to time.
</p>
        </div>
    </section> */}
    
 <section className='section-cover section-cover-a'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Why Choose Us</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     
 {/* <p className='goal-description1'>We partner with the following key stakeholders:</p> */}
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description1'>High-quality services.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description1'>Customer care.</p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description1'>Reliability.</p>
                     </div>
                      <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description1'>Excellence.</p>
                     </div>

{/* <Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link> */}
                </div>
                   

           </article>

           <article className='goals-slides'>
               <MissionCarousel></MissionCarousel>
               {/* <img src='images/16.jpg' className='scope-image'></img> */}

           </article>




       </div>

  </section>

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>Whatever we do, we do it well! Our goal is to provide our clients with first-class products and services. We ensure that our products and services reflect our commitment to providing our clients with a fast, reliable and cost-effective service without compromising on quality.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers></span>
          </div>
          <h3 className='home-service-title'>People</h3>
          <p>At <strong>Spelac Electrical Consults Ltd</strong>, we care about people, our clients and our staff.  We believe the sincerity of this statement is proven by our work quality, strong customer service skills, and dedication to client success.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Leadership</h3>
          <p>There can be no genuine leadership without accountability.  Leadership deals more with responsibility than visibility.  We strive to make certain that our employees understand and perform their responsibilities. </p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaClipboardList></FaClipboardList></span>
          </div>
          <h3 className='home-service-title'>Accountability</h3>
          <p>
           Genuine leadership must also include accountability; therefore, we as a company are accountable to our clients concerning project deadlines, clear communication, and client-centered recommendations.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake>  </span>
          </div>
          <h3 className='home-service-title'>Collaboration</h3>
          <p>Teamwork is the key to success.  We strive to collaborate with our clients and co-workers to achieve positive outcomes.  It is our belief that strong leadership and accountability position us to move towards a common goal of customer satisfaction and project success.</p>
        </div>

        <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake>  </span>
          </div>
          <h3 className='home-service-title'>Ethical Conduct </h3>
          <p>We strive to operate in accordance with the rules and standards for right conduct for engineering and environmental professionals.  We believe that honesty and integrity are vital characteristics of any company culture to maintain credibility with staff and clients.  We not only say what is right, we seek to do what is right.</p>
        </div>

    </div>
        


        </div>


    </div>



  </section> */}
   
    
  
    </>
  )
}

export default Home