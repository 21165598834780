import Carousel from 'react-bootstrap/Carousel';

function MissionCarousel() {
  return (
    <Carousel  indicators={false} className='mission-slides'>   
        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/4.png"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

    </Carousel>
  );
}

export default MissionCarousel;