import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(rgba(172, 35, 129, 0.8),rgba(13, 119, 190, 0.8)), url("./images/6.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

           

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>We Are Located At</h3>
                      <p>
           
Plot 89, Old Butabika Road, Mutungo Bbina, Kampala - Uganda
                        </p>

                    </div>

             </div>
               <div className='footer-inner-content'>


                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                       +256701 388806
<br></br>
+256778 888806
                      </p>

                    </div>

                   
                      

             </div>

          <div className='footer-inner-content'>


                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
 <p>
                     info@habakasevents.com
                        </p>
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="https://www.facebook.com/habakastents" target="_blank" className='social-link'>
      <FaFacebookSquare></FaFacebookSquare>
      {/* <img src='./images/facebook.png' className='social-icon'></img> */}
      </a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'>
      <FaTwitterSquare></FaTwitterSquare>
       {/* <img src='./images/twitter.png' className='social-icon'></img> */}
      </a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'>
      <FaLinkedin></FaLinkedin>
      {/* <img src='./images/linkedin.png' className='social-icon'></img> */}
      </a>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>&copy; {new Date().getFullYear()} <span className="date"></span> Habakas Events | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer